<template>
  <div id="StakingPage">
    <dynamic-title title="Cardano ADA Staking Calculator - Staking Rewards & Profit"></dynamic-title>

    <div class="StakingPage__Top PageMain__Top">
      <site-header></site-header>

      <h1 class="StakingPage__Title">
        <span class="solid-heading">
          Cardano ADA Staking Calculator
        </span>
      </h1>

      <p class="StakingPage__Intro solid-background">
        The ADA staking calculator demonstrates what ADA staking rewards could be expected based on the customisable values set in the parameters section of the calculator. Tinker with the values to see how it effects the ADA staking reward.

        <br>Once the offical staking incentive specification has been released by the Cardano Foundation the calculator will swap out its current default parameters for those written into the specification.

        <br>The calculator, features, and formula used was heavily inspired by the work from Emergo employee and Cardano extraordinaire: 
vantuz-subhuman, and his <a href="https://antipalos.github.io/cardano-calculator/#formula">open-source project</a>.

        <!-- <br><span>Learn about the underlining formulas and <a href="">how the calculator works</a></span> -->
      </p>

      <p class="StakingPage__Warning solid-background">
        <span class="Util-bold StakingPage__WarningTitle">DISCLAIMER:</span> This unofficial calculator is for demonstration/educational purposes only. It currently uses highly speculative default param values.

        The results shown on this page must not be used as financial advise.

        This website and calculator have absolutely no connection with IOHK, Emergo or The Cardano Foundation.
      </p>


      <div class="StakingResults">
        <h3 class="StakingResults__Header">Staking Reward Results:
          <!-- <br>{{this.staking.params.userStake.val}} ADA -->
        </h3>


        <div class="StakingResults__TablesWrapper">

          <table class="StakingResults__Table StakingResults_FFFFFF_Summary">
            <tr>
              <th class="Util-right">Total ADA Staked:</th>
              <td>
                <span class="StakingResults__Button">
                  ₳ {{ this.staking.params.userStake.val }}
                </span>
              </td>
            </tr>
            <tr>
              <th class="Util-right">Total ADA Reward:</th>
              <td>
                <span class="StakingResults__Button">
                  ₳ {{ this.fixedDecimal(this.comp_resultAtYearEnd) }}
                </span>
              </td>
            </tr>
            <tr v-if="this.fixedDecimal(this.comp_resultAtYearEnd * this.$store.getters.getSelectedConvData.ratio, 2 )">
              <th class="Util-right">
                <span data-balloon="Change the currency by visiting the 'Price' page then toggling the 'Select Currency' dropdown. This price calculation pulls the latest prices from CoinGecko's API" data-balloon-pos="left" data-balloon-length="medium">
                  Total Reward In
                  <span class="Util-underline">{{this.$store.getters.getSelectedConvData.currency}}</span>:
                </span>
              </th>

              <td>
                <span class="StakingResults__Button">
                  {{this.$store.getters.getSelectedConvData.label}}
                  {{ this.fixedDecimal(this.comp_resultAtYearEnd * this.$store.getters.getSelectedConvData.ratio, 2 ) }}
                </span>
              </td>
            </tr>
            <tr>
              <th class="Util-right">ROI:</th>
              <td>
                <span class="StakingResults__Button">
                  {{ this.fixedDecimal((this.comp_resultAtYearEnd * 100) / this.staking.params.userStake.val, 3) }} %
                </span>
              </td>
            </tr>
          </table>

          <table class="StakingResults__Table StakingResults__DWMY">
            <tr class="StakingResults__TableHeaderRow">
              <th class="StakingResults__TableHeading">Time Avg.</th>
              <th>ADA Reward</th>
            </tr>
            <tr class="StakingResults__TableRow--thinUnderline">
              <td class="StakingResults__TableHeading">Per Day</td>
              <td>₳{{ this.fixedDecimal(this.comp_resultAtYearEnd_daily) }}</td>
            </tr>
            <tr class="StakingResults__TableRow--thinUnderline">
              <td class="StakingResults__TableHeading">Per Week</td>
              <td>₳{{ this.fixedDecimal(this.comp_resultAtYearEnd_weekly) }}</td>
            </tr>
            <tr class="StakingResults__TableRow--thinUnderline">
              <td class="StakingResults__TableHeading">Per Month</td>
              <td>₳{{ this.fixedDecimal(this.comp_resultAtYearEnd_monthly) }}</td>
            </tr>
            <tr>
              <td class="StakingResults__TableHeading">Per Year</td>
              <td>₳{{ this.fixedDecimal(this.comp_resultAtYearEnd_yearly) }}</td>
            </tr>
          </table>

          <table v-if="this.comp_issuedAtYearStart" class="StakingResults__Table StakingResults__SDE">
            <tr class="StakingResults__TableHeaderRow">
              <th class="StakingResults__TableHeading">Time</th>
              <th class="StakingResults__TableHeading">ADA Reward</th>
              <th>ROI</th>
            </tr>
            <tr class="StakingResults__TableRow--thinUnderline">
              <td class="StakingResults__TableHeading">Start of {{this.staking.params.year.val}}</td>
              <td class="StakingResults__TableHeading">₳{{ this.fixedDecimal(this.comp_resultAtYearStart) }}</td>
              <td>{{ this.fixedDecimal((this.comp_resultAtYearStart * 100) / this.staking.params.userStake.val, 3) }}%</td>
            </tr>
            <tr class="StakingResults__TableRow--thinUnderline">
              <td class="StakingResults__TableHeading">During {{this.staking.params.year.val}}</td>
              <td class="StakingResults__TableHeading">₳{{ this.fixedDecimal(this.comp_resultThisYear) }}</td>
              <td>{{ this.fixedDecimal((this.comp_resultThisYear * 100) / this.staking.params.userStake.val, 3) }}%</td>
            </tr>
            <tr>
              <td class="StakingResults__TableHeading">End of {{this.staking.params.year.val}}</td>
              <td class="StakingResults__TableHeading">₳{{ this.fixedDecimal(this.comp_resultAtYearEnd) }}</td>
              <td>{{ this.fixedDecimal((this.comp_resultAtYearEnd * 100) / this.staking.params.userStake.val, 3) }}%</td>
            </tr>
          </table>

        </div> <!-- End .StakingResults__TablesWrapper -->
      </div> <!-- End .StakingResults -->


      <div v-if="this.$store.state.Staking.showSubscribe" class="Subscribe__wrapper">
        <div class="Subscribe__content">
          <h2 class="Subscribe__Heading Util-xs-center">Subscribe<span class="Util-hidden-xs">: 
          <br>Our AdaStaking.com Mailing List</span></h2>
          <p class="Subscribe__Body">
            Joining the mailing list is your first step <span class="Util-bold">towards earning passive ADA</span>.
            <br>Be the first to know when staking becomes available.
          </p>
          <div @click="hideSubscribe" class="Util-point Subscribe__Close">&#x2716;</div>
          <!-- Begin MailChimp Signup Form -->
          <div id="mc_embed_signup">
            <form action="https://adastaking.us12.list-manage.com/subscribe/post?u=a5683519f11a72faeaa5fe3bd&amp;id=8f728d184f" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate="">
              <div id="mc_embed_signup_scroll">
                <div class="mc-field-group">
                  <!-- <label for="mce-EMAIL">Email Address </label> -->
                  <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="Enter Your E-mail Address:">
                </div>
                <div id="mce-responses" class="clear">
                  <div class="response" id="mce-error-response" style="display:none"></div>
                  <div class="response" id="mce-success-response" style="display:none"></div>
                </div>    <!-- realz people should not fill this in and expect good things - do not remove this or risk form botz signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_a5683519f11a72faeaa5fe3bd_8f728d184f" tabindex="-1" value=""></div>
                <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
              </div>
            </form>
          </div><!--End mc_embed_signup-->
        </div><!--End .Subscribe__content-->
      </div>


      <h3 class="Util-center">Cardano Staking Calculator Parameters:</h3>
      <div class="StakingParams">

        <p class="StakingParams__HoverTip Util-hidden-xs">
          <span
            data-balloon="Hovering over titles, numbers and text will display a detailed descriptions for each calculator section!"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            Hover for tips
          </span>
        </p>

        <h4 class="StakingParams__SectionTitle">
          <span
            data-balloon="Parameters specific to the user only" 
            data-balloon-pos="right"
            data-balloon-length="small"
          >User Parameters</span>
        </h4>
        <div class="StakingParamEntry">
          <div
            data-balloon="Enter the total amount of ADA coins you will stake"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <label for="sc-input__stakingAmount" class="stakeInputLabel">
              ADA Stake Amount
            </label>
            <span
              v-if="staking.params.userStake.error"
              v-text="staking.params.userStake.error"
              class="stakingErrorMessage"
            ></span>
            <input 
              id="sc-input__stakingAmount"
              type="number"
              v-model.number="staking.params.userStake.val" 
              @input="this.validateForm"
              :class="{stakingInputError: staking.params.userStake.error}"
            >
          </div>
          <span
            class="StakingParamEntry__Meta" 
            data-balloon="Shows the percentage of total currently staked ADA that belongs to you"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_userShare * 100) }}%
            </span> 
             of total ADA staked
          </span>
          <span
            class="StakingParamEntry__Meta" 
            data-balloon="Shows the percentage of ADA you own out of the current circulating ADA supply"
            data-balloon-pos="left"
            data-balloon-length="small"
            v-if="this.fixedDecimal(this.comp_userShareOfTotalADA * 100)"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_userShareOfTotalADA * 100) }}%
            </span> 
             of total ADA supply for {{ this.staking.params.year.val }}
          </span>
        </div>

        <div class="StakingParamEntry">
          <div
            data-balloon="Enter the year you will be staking in/until"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <label for="sc-input__stakingYear" class="stakeInputLabel">Year</label>
            <span
              v-if="staking.params.year.error"
              v-text="staking.params.year.error"
              class="stakingErrorMessage"
            ></span>
            <input 
              id="sc-input__stakingYear"
              type="number"
              min="2019"
              v-model.number="staking.params.year.val"
              @input="this.validateForm"
              :class="{stakingInputError: staking.params.year.error}"
            >
          </div>
          <span class="StakingParamEntry__Meta"
            data-balloon="Shows the total ADA supply in circulation at beginning of 
            your chosen year"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_supplyAtYearStart, 3) }}
            </span>
            total ADA supply at start of {{ this.staking.params.year.val }}
          </span>
          <span class="StakingParamEntry__Meta"
            data-balloon="Shows the total ADA remaining in the Cardano reward pool reserve at the start of your chosen year"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_reserveAtYearStart, 3) }}
            </span>
            total remaining ADA in reward pool at start of {{ this.staking.params.year.val }}
          </span>
        </div>

        <h4 class="StakingParams__SectionTitle">
          <span
            data-balloon="Parameters that will dynamically change reflecting the popularity and adoption of the Cardano network." 
            data-balloon-pos="right"
            data-balloon-length="small"
          >Environment Parameters</span>
        </h4>
        <div class="StakingParamEntry">
          <div
            data-balloon="Enter the total number of ADA coins actively participating in staking"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <label for="sc-input__totalStake" class="stakeInputLabel">Total ADA Staked</label>
            <span 
              v-if="staking.params.totalStake.error"
              v-text="staking.params.totalStake.error"
              class="stakingErrorMessage"
            ></span>
            <input 
              id="sc-input__totalStake"
              type="number"
              v-model.number="staking.params.totalStake.val" 
              @input="this.validateForm"
              :class="{stakingInputError: staking.params.totalStake.error}"
            >
          </div>
          <span class="StakingParamEntry__Meta"
            data-balloon="Shows percentage of ADA that is participating in staking out of the total coin supply for the end of your chosen year"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_stakedShare) }}%
            </span>
            of {{ this.staking.params.year.val }}s total ADA supply is staked
          </span>
        </div>

        <div class="StakingParamEntry">
          <div
            data-balloon="Enter the amount of transactions completed per epoch (approx. 5 days)"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <label for="sc-input__txPerEpoch" class="stakeInputLabel">Average TX per Epoch</label>
            <span 
              v-if="staking.params.TXEpoch.error"
              v-text="staking.params.TXEpoch.error"
              class="stakingErrorMessage"
            ></span>
            <input 
              id="sc-input__txPerEpoch"
              type="number"
              v-model.number="staking.params.TXEpoch.val" 
              @input="this.validateForm"
              :class="{stakingInputError: staking.params.TXEpoch.error}"
            >
          </div>
          <span class="StakingParamEntry__Meta"
            data-balloon="Shows the average number of transactions per second assuming a 5 day epoch made up of 20 second slots"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_tps) }}
            </span>
            transactions per second
          </span>
        </div>

        <div class="StakingParamEntry">
          <div
            data-balloon="Enter the average transaction size in bytes"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <label for="sc-input__txSizeBytes" class="stakeInputLabel">Transaction Size (bytes)</label>
            <span 
              v-if="staking.params.TXSize.error"
              v-text="staking.params.TXSize.error"
              class="stakingErrorMessage"
            ></span>
            <input 
              id="sc-input__txSizeBytes"
              type="number"
              v-model.number="staking.params.TXSize.val" 
              @input="this.validateForm"
              :class="{stakingInputError: staking.params.TXSize.error}"
            >
          </div>
          <span class="StakingParamEntry__Meta"
            data-balloon="Shows the average transaction fee in ADA"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_txFee) }}
            </span>
            avg transaction fee
          </span>
          <span class="StakingParamEntry__Meta"
            data-balloon="Shows the average block reward in ADA after deducting the Cardano treasury tax"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_blockReward) }}
            </span>
            avg taxed block reward
          </span>
        </div>

        <h4 class="StakingParams__SectionTitle">
          <span
            data-balloon="Parameters defined by the protocol. These values will be available with the completion of the Shelley release."
            data-balloon-pos="right"
            data-balloon-length="small"
          >System Parameters</span>
        </h4>
        <div class="StakingParamEntry">
          <div
            data-balloon="Enter the percentage of coins to be minted from the ADA reserve each year (the yearly reserve decrease)"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <label for="sc-input__inflation" class="stakeInputLabel">Inflation (%)</label>
            <span 
              v-if="staking.params.inflation.error"
              v-text="staking.params.inflation.error"
              class="stakingErrorMessage"
            ></span>
            <input 
              id="sc-input__inflation"
              type="number"
              v-model.number="staking.params.inflation.val" 
              @input="this.validateForm"
              :class="{stakingInputError: staking.params.inflation.error}"
            >
          </div>
          <span class="StakingParamEntry__Meta"
            data-balloon="Shows the total ADA supply inflation for the chosen year (the percentage of ADA added into the system for specified year)"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_supplyInflation) }}%
            </span>
            supply inflation for {{ this.staking.params.year.val }}
          </span>
          <span class="StakingParamEntry__Meta"
            data-balloon="Shows how many newly created ADA coins will be added to the circulating supply for the chosen year"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_issuedThisYear, 3) }}
            </span>
            new minted ADA coins issued for {{ this.staking.params.year.val }}
          </span>
        </div>

        <div class="StakingParamEntry">
          <div
            data-balloon="Enter the tax rate percentage that the Cardano treasury will receive out of all profits. (IOHK proposed a 20% tax for Ethereum Classic)"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <label for="sc-input__treasuryTax" class="stakeInputLabel">Treasury Tax (%)</label>
            <span 
              v-if="staking.params.tax.error"
              v-text="staking.params.tax.error"
              class="stakingErrorMessage"
            ></span>
            <input 
              id="sc-input__treasuryTax"
              type="number"
              v-model.number="staking.params.tax.val" 
              @input="this.validateForm"
              :class="{stakingInputError: staking.params.tax.error}"
            >
          </div>
          <span class="StakingParamEntry__Meta" 
            v-if="this.fixedDecimal(this.comp_taxAtYearStart, 3)"
            data-balloon="Shows how many ADA coins the treasury possesses at the start of the chosen year"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_taxAtYearStart, 3) }}
            </span>
            ADA in treasury at start of {{ this.staking.params.year.val }}
          </span>
          <span class="StakingParamEntry__Meta"
            data-balloon="Shows how many taxed ADA will be added into the Cardano treasury fund during the chosen year"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_taxThisYear, 3) }}
            </span>
            ADA added to treasury for duration of {{ this.staking.params.year.val }}
          </span>
        </div>

        <h4 class="StakingParams__SectionTitle">
          <span
            data-balloon="Parameters defined by the staking pool you have chosen to delegate your stake to"
            data-balloon-pos="right"
            data-balloon-length="small"
          >Pool Parameters</span>
        </h4>
        <div class="StakingParamEntry">
          <div
            data-balloon="Enter your staking pools fee rate as a percentage"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <label for="sc-input__stakePoolFee" class="stakeInputLabel">Stake Pool Fee (%)</label>
            <span
              v-if="staking.params.poolFee.error"
              v-text="staking.params.poolFee.error"
              class="stakingErrorMessage"
            ></span>
            <input 
              id="sc-input__stakePoolFee"
              type="number"
              v-model.number="staking.params.poolFee.val" 
              @input="this.validateForm"
              :class="{stakingInputError: staking.params.poolFee.error}"
            >
          </div>
          <span class="StakingParamEntry__Meta"
            data-balloon="Shows the ADA fee you will pay to your staking pool for the end of your chosen year only"
            data-balloon-pos="left"
            data-balloon-length="small"
          >
            <span class="StakingParamEntry__MetaNum">
              {{ this.fixedDecimal(this.comp_poolFeeThisYear) }}
            </span>
            yearly ADA stake pool fee
          </span>
        </div>

      </div> <!-- End .StakingParams -->

    </div> <!-- End .StakingPage__Top PageMain__Top -->
  </div> <!-- End #StakingPage -->
</template>

<script>
import axios from 'axios';

export default {
  data: () => {
    return {
      staking: {
        params: {
          userStake: { val: 1000, error: '' }, // - your stake in coins
          year: { val: 2019, error: '' }, // - number of full years since the launch of the staking

          // Populated by API
          inflation: {val: 5, error: ''},
          totalStake: {val: 15000000000, error: ''},
          TXEpoch: {val: 15000, error: ''},
          TXSize: {val: 458, error: ''},
          tax: {val: 20, error: ''},
          poolFee: {val: 5, error: ''},

          // Non-input field params:
          initialTotalSupply: {val: 31112484646, error: ''},
          initialReserve: {val: 13887515354, error: ''},
          txFeeFixed: {val: 0.155381, error: ''},
          txFeePerByte: {val: 0.000043946, error: ''},
        },
      },
    }
  },

  mounted () {
    this.fetchPriceData();
    //this.fetchStakingData();
    this.initShowSubscription();
  },

  computed: {
    comp_year () {
      return this.staking.params.year.val - 2019;
    },
    comp_inflation () {
      return this.staking.params.inflation.val / 100;
    },
    comp_tax () {
      return this.staking.params.tax.val / 100;
    },
    comp_poolFee () {
      return this.staking.params.poolFee.val / 100;
    },
    comp_userShare () {
      return this.staking.params.userStake.val / this.staking.params.totalStake.val;
    },
    comp_reserveAtYearStart () {
      return this.staking.params.initialReserve.val * Math.pow((1 - this.comp_inflation), this.comp_year)
    },
    comp_issuedAtYearStart () {
      return this.staking.params.initialReserve.val - this.comp_reserveAtYearStart;
    },
    comp_supplyAtYearStart () {
      return this.staking.params.initialTotalSupply.val + this.comp_issuedAtYearStart;
    },
    comp_issuedThisYear () {
      return this.comp_reserveAtYearStart * this.comp_inflation;
    },
    comp_supplyInflation () {
      return ( this.comp_issuedThisYear * 100 ) / this.comp_supplyAtYearStart;
    },
    comp_stakedShare () {
      return (this.staking.params.totalStake.val * 100) / this.comp_supplyAtYearStart;
    },
    comp_tpb () {
      return this.staking.params.TXEpoch.val / 21600;
    },
    comp_tps () {
      return this.comp_tpb / 20;
    },
    comp_txFee () {
      return this.staking.params.txFeeFixed.val +
      (this.staking.params.TXSize.val * this.staking.params.txFeePerByte.val);
    },
    comp_blockReward () {
      return ((this.comp_issuedThisYear / (73 * 21600)) + (this.comp_tpb * this.comp_txFee)) * (1 - this.comp_tax)
    },
    comp_txFeeYearly () {
      return this.staking.params.TXEpoch.val * 73 * this.comp_txFee;
    },
    comp_untaxedRewardAtYearStart () {
      return this.comp_issuedAtYearStart + (this.comp_txFeeYearly * this.comp_year);
    },
    comp_untaxedRewardThisYear () {
      return this.comp_issuedThisYear + this.comp_txFeeYearly;
    },
    comp_taxAtYearStart () {
      return this.comp_untaxedRewardAtYearStart * this.comp_tax;
    },
    comp_taxThisYear () {
      return this.comp_untaxedRewardThisYear * this.comp_tax;
    },
    comp_taxedRewardAtYearStart () {
      return  this.comp_untaxedRewardAtYearStart - this.comp_taxAtYearStart;
    },
    comp_taxedRewardThisYear () {
      return this.comp_untaxedRewardThisYear - this.comp_taxThisYear;
    },
    comp_userRewardAtYearStart () {
      return this.comp_taxedRewardAtYearStart * this.comp_userShare;
    },
    comp_userRewardThisYear () {
      return this.comp_taxedRewardThisYear * this.comp_userShare;
    },
    comp_poolFeeAtYearStart () {
      return this.comp_userRewardAtYearStart * this.comp_poolFee;
    },
    comp_poolFeeThisYear () {
      return this.comp_userRewardThisYear * this.comp_poolFee;
    },
    comp_resultAtYearStart () {
      return this.comp_userRewardAtYearStart - this.comp_poolFeeAtYearStart;
    },
    comp_resultThisYear () {
      return this.comp_userRewardThisYear - this.comp_poolFeeThisYear;
    },
    comp_resultAtYearEnd () {
      return this.comp_resultAtYearStart + this.comp_resultThisYear;
    },
    comp_resultAtYearEnd_yearly () {
      return (this.comp_resultAtYearEnd / (this.comp_year + 1) );
      //return this.comp_resultAtYearEnd / 12;
    },
    comp_resultAtYearEnd_monthly () {
      return (this.comp_resultAtYearEnd / (this.comp_year + 1) ) / 12;
      //return this.comp_resultAtYearEnd / 12;
    },
    comp_resultAtYearEnd_weekly () {
      return (this.comp_resultAtYearEnd / (this.comp_year + 1) ) / 52;
      //return this.comp_resultAtYearEnd / 52;
    },
    comp_resultAtYearEnd_daily () {
      return (this.comp_resultAtYearEnd / (this.comp_year + 1) ) / 365;
      //return this.comp_resultAtYearEnd / 365;
    },
    comp_userShareOfTotalADA () {
      //return 10;
      return this.staking.params.userStake.val / this.comp_supplyAtYearStart;
    },
  },

  methods: {
    resetErrors() { // reset all 'this.staking.params' errors before re-validating form
      for (var key in this.staking.params) {
        if (this.staking.params.hasOwnProperty(key)) {
          this.staking.params[key].error = '';
        }
      }
    },
    validateForm() {
      console.log('validating form');
      //let activeFocusedElID = document.activeElement.id;

      this.resetErrors();

      // check year is greater than 2018
      if(this.staking.params.year.val < 2018) {
        this.staking.params.year.error = 'Year must be greater than 2018';
      }

      // check userStake < totalStake
      if(this.staking.params.userStake.val > this.staking.params.totalStake.val) {
        this.staking.params.userStake.error = 'Stake must be less than total ADA currently staked.';
        //this.staking.params.totalStake.error = 'Total ADA staked must be greater than your ADA stake.';
      }

      // check userStake > 0
      if(this.staking.params.userStake.val < 1) {
        this.staking.params.userStake.error = 'Stake must be greater than 1 ADA';
      }

      // check inflation percent is less than 100
      if(this.staking.params.inflation.val > 100) {
        this.staking.params.inflation.error = 'Inflation must be less than 100%';
      }

      // check tax percent is less than 100
      if(this.staking.params.tax.val > 100) {
        this.staking.params.tax.error = 'Tax rate must be less than 100%';
      }

      // check poolFee percent is less than 100
      if(this.staking.params.poolFee.val > 100) {
        this.staking.params.poolFee.error = 'Pool Fee must be less than 100%';
      }

      // check total ADA currently staked is greater than supplyAtYearStart
      if(this.staking.params.totalStake.val > this.comp_supplyAtYearStart) {
        this.staking.params.totalStake.error = 'Total ADA staked must be less than total ADA supply for this year.';
      }
    },
    fixedDecimal(num, scale=6) {
      return Number(num.toFixed(scale));
    },
    // getStakingApiUrl() { //TODO: make prod/dev version work
    //   return 'http://localhost:3020/api/v1/staking';
    // },
    // fetchStakingData() { // make the API call for latest staking details
    //   axios.get(this.getStakingApiUrl())
    //   .then((response) => {
    //     this.storeAPIResponse(response.data);
    //   })
    //   .catch((error) => {
    //     // handle error
    //     console.log(error);
    //   })
    //   .then(() => {
    //     // always executed
    //   });
    // },
    // storeAPIResponse(response) { // overwrite this components data with API data
    //   for (var key in response) {
    //     if (response.hasOwnProperty(key)) {
    //       this.staking.params[key].val = response[key].val;
    //       // console.log(key)
    //       // console.log(response[key].val)
    //       //response[key].error = '';
    //     }
    //   }
    // }
    fetchPriceData() {
      //console.log('fetchPriceData');
      this.$store.dispatch('fetchData');
    },
    initShowSubscription() {
      let secondsUntilShowSubsc = 259200; // 3 days in seconds

      // stores seconds subscription form has been hidden for
      let subscriptionHiddenTime = Math.floor(Date.now() / 1000) - this.$store.state.Staking.subscribeHiddenOn;

      if(subscriptionHiddenTime > secondsUntilShowSubsc) {
        this.$store.commit('showSubscribe');
      }
    },
    hideSubscribe() {
      // pass current time in seconds to the mutation
      this.$store.commit('hideSubscribe', Math.floor(Date.now() / 1000));
    },
  },
};
</script>

<style>

</style>
